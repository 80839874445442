import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'; 
import { CardHeader, CardContent, CardActions, List, ListItem, Grid, Stack, Switch, styled, Icon } from '@mui/material';
import Card from '../../components/UI/molecules/Card';
import Text from '../../components/UI/atoms/Text';
import Button from '../../components/UI/atoms/Button';
import { useAccount } from '../../components/features/account/context';
import StyledContainer from '../../components/UI/atoms/StyledContainer';
import { useApp } from '../../AppContext';
import { formatPlans } from '../../lib/formatPlans';
import InfoMessage from '../../components/UI/atoms/InfoMessage';
import Divider from '../../components/UI/atoms/Divider';

const StyledWrapper = styled(StyledContainer)(() => ({
    maxWidth: '1090px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
}))

const StyledSwitch = styled(Switch)(({theme}) => ({
    position: 'absolute',
    opacity: '0',
    width: '40px',
    height: '22px',
    padding: '2px',
    '& .MuiSwitch-switchBase': {
        padding: '1px'
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#FFF',
        border: `1px solid ${theme.palette.primary.main}`
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        '& .MuiSwitch-thumb': {
            backgroundColor: '#FFF',
            border: `1px solid ${theme.palette.primary.main}`
        },
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main
        },
    }
}))
const StyledTrialButton = styled(Button)(() => ({
    marginBottom: '10px',
    backgroundColor: '#ecebeb !important',
    color: '#201E1E !important',
    fontSize: '12px !important',
    '&:hover': {
        backgroundColor: '#d9d9d9 !important',
        
    },
}))
const StyledTrialText = styled(Text)(() => ({
    fontSize: '12px',
    maxWidth: '600px',
    textAlign: 'center',
    margin: '30px auto 10px',
}))

export default function SelectPlan() {
    const { plans, frequency, handlePlanFrequency, handleCheckoutSession } = useAccount();
    const { handleLoading } = useApp();
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(true);
    const [searchParams] = useSearchParams();
    const [trialEligible, setTrialEligible] = React.useState(true);

    useEffect(() => {
        handleLoading(false);
        const freq = checked ? 'Annual' : 'Monthly';
        handlePlanFrequency({type: freq});

        if (searchParams.get('trial-inelegible') !== null) {
            setTrialEligible(false);
        }
        // eslint-disable-next-line
    }, []);

    const selectMembership = (plan) => {
        if (!trialEligible) {
            if (plan === 'TNM_BASIC') {
                navigate(`/digests`);
            } else {
                handleCheckoutSession(plan);
            }
        } else {
            if (plan === 'TNM_BASIC') {
                navigate(`/create-account/${plan}`);
            } else {
                navigate(`/create-account/${plan}-${frequency.type}`);
            }
        }
    }
    const selectTrial = (plan) => {
        navigate(`/create-account/${plan}-${frequency.type}-trial`);
    }

    const handleChange = (event) => {
        setChecked(event.target.checked);
        const freq = event.target.checked ? 'Annual' : 'Monthly';
        handlePlanFrequency({type: freq});
    };
    const fullYearPrice = productName => {
        const fullPlan = plans.find(plan => plan.productName === productName && plan.renewalType === 'Monthly');
        const price = fullPlan.name.split(' ')[0].split('$')[1];
        return Math.round(parseFloat(price) * 12);
    }
    const featureIcon = (plan, index) => {
        const included = 'fa-circle-check';
        const additional = 'fa-circle-plus';

        if (plan.productName === 'Free') {
            return included;
        } else {
            if (index == 0) {
                return included;
            } else {
                return additional;
            }
        }
    }

  return (
    <StyledWrapper className='select-plan'>
        {!trialEligible && (
            <InfoMessage>
                <Text sx={{fontWeight: 700, fontSize: '1.5rem', textTransform: 'uppercase', marginBottom: '10px'}}>Account Not Eligible for trial</Text>
                <Text sx={{marginBottom: '10px'}}>Our records indicate that this account has already redeemed a Free Trial of TNM Membership and is therefore no longer eligible.</Text>
                <Text>To continue using the benefits of TNM Membership, please select one of the paid options below, or continue your free BASIC NEWS membership.</Text>
            </InfoMessage>
        )}
        <Text type='h1'>Select a Membership Plan</Text>
        {!plans.length ? 
            <Text>Loading...</Text>
        :
            <div>
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                    <Text sx={{fontWeight: 600, color: !checked ? '#413F3F' : '#918F8F'}}>Monthly</Text>
                    <StyledContainer sx={{position: 'relative'}}>
                        <StyledSwitch checked={checked} onChange={handleChange} />
                        {checked ?
                            <Icon baseClassName="fa-light" className="fa-toggle-large-on" sx={{width: '30px', height: '20px'}} />
                        :
                            <Icon baseClassName="fa-light" className="fa-toggle-large-off" sx={{width: '30px', height: '20px'}} />
                        }
                    </StyledContainer>
                    <Text sx={{fontWeight: 600, color: checked ? '#413F3F' : '#918F8F'}}>Annually</Text>
                </Stack>
                <Grid container spacing={2} sx={{height: '100%', marginTop: '4px', width: '100%', ml: 0}}>
                    {plans.filter(plan => formatPlans(plan, frequency.type)).map((plan) => (
                        <Grid key={plan.subscriptionTag} item xs={12} sm={12} md={4}>
                            <Card type='paddless' sx={{borderRadius: 0, maxWidth: '350px', margin: {xs: 'auto', sm: 'auto', md: 0}, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <StyledContainer>
                                    <CardHeader
                                        title={<Text
                                            sx={{
                                                color: plan.productName === 'Free' ? '#373535' : '#ffffff',
                                                fontSize: '1.5rem',
                                                fontWeight: '700',
                                                textTransform: 'uppercase'
                                            }}>
                                                {plan.readableName}
                                        </Text>}
                                        sx={{
                                            backgroundColor: plan.productName === 'Free' ? '#ECEBEB' : '#373535',
                                            textAlign: 'center',
                                            padding: '20px'
                                        }}
                                    />
                                    <CardContent sx={{padding: '20px'}}>
                                        {plan.productName === 'Free' ? (
                                            <StyledContainer sx={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100px'}}>
                                                <Text sx={{fontWeight: '700', fontSize: '3rem', textTransform: 'uppercase', lineHeight: 1}}>{plan.name.split(" ")[0]}</Text>
                                                <Text sx={{fontWeight: '700', fontSize: '1.125rem', textTransform: 'uppercase'}}>{plan.name.split(" ")[1]}</Text>
                                            </StyledContainer>

                                        ) : (
                                            <StyledContainer sx={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100px'}}>
                                                {checked ?
                                                    <React.Fragment>
                                                        <Text sx={{fontWeight: '800', fontSize: '1.5rem', textTransform: 'uppercase', lineHeight: 1, textDecoration: 'line-through', color: '#cdc384'}}>${fullYearPrice(plan.productName)}</Text>
                                                        <Text sx={{fontWeight: '700', fontSize: '3rem', textTransform: 'uppercase', lineHeight: 1}}>{plan.name.split(" ")[0]}</Text>
                                                    </React.Fragment>
                                                :
                                                    <Text sx={{fontWeight: '700', fontSize: '3rem', textTransform: 'uppercase', lineHeight: 1}}>{plan.name.split(" ")[0]}</Text>
                                                }
                                                <Text sx={{fontWeight: '900', fontSize: '1.125rem', textTransform: 'uppercase'}}>{plan.name.split(" ")[1]}</Text>
                                            </StyledContainer>
                                        )}
                                        <StyledContainer sx={{marginTop: '20px'}}>
                                            <List sx={{padding: '5px 0', marginLeft: '5px'}}>
                                                {plan.features.marketing.map((feature, index) => (
                                                    <ListItem key={`feature-${index}`} sx={{padding: 0, alignItems: 'baseline'}}>
                                                        <Icon sx={{fontSize: '14px', marginRight: '10px', color: '#AB9F60'}} baseClassName='fa-regular' 
                                                        className={featureIcon(plan, index)} />
                                                        <Text>{feature}</Text>
                                                    </ListItem>
                                                ))}
                                                {plan.productName === 'NewsData' &&
                                                    <React.Fragment>
                                                        <Divider plan />
                                                        <StyledContainer sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <Text sx={{fontSize: '14px', textTransform: 'uppercase', textAlign: 'center'}}>Access to a Wealth of Global Mining Data with</Text>
                                                            <a href='https://marcopolo.tnm.global' target='_blank' rel='noopener noreferrer'>
                                                            <img style={{maxWidth: '215px'}} alt='Marco Polo logo' src='/images/marco-polo-logo.png' />
                                                            </a>
                                                        </StyledContainer>
                                                    </React.Fragment>
                                                }
                                            </List>
                                        </StyledContainer>
                                    </CardContent>
                                </StyledContainer>
                                <CardActions sx={{flexDirection: 'column', padding: '20px', paddingTop: 0}}>
                                    {plan.subscriptionTag === 'TNM_BASIC' ? (
                                        <Button type='outlined' onClick={() => selectMembership(plan.subscriptionTag)}>{trialEligible ? 'Create your account' : 'Current Membership'}</Button>
                                    ) : (
                                        <div style={{width: '100%'}}>
                                            {trialEligible && (
                                                <StyledTrialButton onClick={() => selectTrial(plan.subscriptionTag)}>Get started with a free 14-day trial *</StyledTrialButton>
                                            )}
                                            <Button sx={{marginLeft: '0 !important'}} onClick={() => selectMembership(plan.subscriptionTag)}>Become a Member</Button>
                                        </div>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <StyledTrialText >* Credit card required to begin free trial. Your card will be charged 14 days from signup. You will receive an email notification seven (7) days before the free trial period ends.</StyledTrialText>
            </div>
        }
    </StyledWrapper>
  )
}