import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'; 
import { CardHeader, CardContent, CardActions, List, ListItem, Grid, Stack, Switch, styled, Icon } from '@mui/material';
import Card from '../../components/UI/molecules/Card';
import Text from '../../components/UI/atoms/Text';
import Button from '../../components/UI/atoms/Button';
import { useAccount } from '../../components/features/account/context';
import { useDigest } from '../../components/features/digests/context'
import StyledContainer from '../../components/UI/atoms/StyledContainer';
import { useApp } from '../../AppContext';
import { formatPlans } from '../../lib/formatPlans';
import Divider from '../../components/UI/atoms/Divider';

const StyledWrapper = styled(StyledContainer)(() => ({
    maxWidth: '1090px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
}))

const StyledSwitch = styled(Switch)(({theme}) => ({
    width: '40px',
    height: '22px',
    padding: '2px',
    '& .MuiSwitch-switchBase': {
        padding: '1px'
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#FFF',
        border: `1px solid ${theme.palette.primary.main}`
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        '& .MuiSwitch-thumb': {
            backgroundColor: '#FFF',
            border: `1px solid ${theme.palette.primary.main}`
        },
        '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.primary.main
        },
    }
}))

export default function SelectSubscription() {
    const {  plans_to_renew, planInfo, frequency, handlePlanFrequency, handleCheckoutSession, currentSubscription} = useAccount();
    const { handleSetSubsDigNews } = useDigest();
    const { handleLoading } = useApp();
    const [checked, setChecked] = React.useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [trialEligible, setTrialEligible] = React.useState(true);

    useEffect(() => {
        handleLoading(false);
        handleSetSubsDigNews();
        const prod= planInfo;
        const freq = planInfo.renewalType == 'Annual' ? 'Annual' : 'Monthly';
        setChecked(freq);
        handlePlanFrequency({type: freq});

        if (searchParams.get('trial-inelegible') !== null) {
            setTrialEligible(false);
        }
        // eslint-disable-next-line
    }, []);

    const selectMembership = (plan) => {
        
        handleCheckoutSession(plan);
    }
    const handleChange = (event) => {
        setChecked(event.target.checked);
        const freq = event.target.checked ? 'Annual' : 'Monthly';
        handlePlanFrequency({type: freq});
    };
    const handleRedirectBack = () => {
        navigate(-1);
    }
    const fullYearPrice = productName => {
        const fullPlan = plans_to_renew.find(plan => plan.productName === productName && plan.renewalType === 'Monthly');
        const price = fullPlan.name.split(' ')[0].split('$')[1];
        return Math.round(parseFloat(price) * 12);
    }
    const featureIcon = (plan, index) => {
        const included = 'fa-circle-check';
        const additional = 'fa-circle-plus';

        if (plan.productName === 'Free') {
            return included;
        } else {
            if (index == 0) {
                return included;
            } else {
                return additional;
            }
        }
    }

  return (
    <StyledWrapper>
        <Text type='h1'>Select a Membership Plan</Text>
        {!plans_to_renew.length ? 
            <Text>Loading...</Text>
        :
            <div>
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                    <Text sx={{fontWeight: 600, color: !checked ? '#413F3F' : '#918F8F'}}>Monthly</Text>
                    <StyledSwitch checked={checked} onChange={handleChange} />
                    <Text sx={{fontWeight: 600, color: checked ? '#413F3F' : '#918F8F'}}>Annually</Text>
                </Stack>
                <Grid container spacing={2} sx={{height: '100%', marginTop: '4px', width: '100%', ml: 0}}>
                    {plans_to_renew.filter(plan => formatPlans(plan, frequency.type)).map((plan) => (
                        <Grid key={plan.subscriptionTag} item xs={12} sm={12} md={plans_to_renew.length === 2 ? 12: 6}>
                            <Card type='paddless' sx={{borderRadius: 0, maxWidth: '350px', margin: {xs: 'auto', sm: 'auto', md: 0}, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <StyledContainer>
                                    <CardHeader
                                        title={<Text
                                            sx={{
                                                color: plan.productName === 'Free' ? '#373535' : '#ffffff',
                                                fontSize: '1.5rem',
                                                fontWeight: '700',
                                                textTransform: 'uppercase'
                                            }}>
                                                {plan.readableName}
                                        </Text>}
                                        sx={{
                                            backgroundColor: plan.productName === 'Free' ? '#ECEBEB' : '#373535',
                                            textAlign: 'center',
                                            padding: '20px'
                                        }}
                                    />
                                    <CardContent sx={{padding: '20px'}}>
                                    {plan.productName === 'Free' ? (
                                            <StyledContainer sx={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100px'}}>
                                                <Text sx={{fontWeight: '700', fontSize: '3rem', textTransform: 'uppercase', lineHeight: 1}}>{plan.name.split(" ")[0]}</Text>
                                                <Text sx={{fontWeight: '700', fontSize: '1.125rem', textTransform: 'uppercase'}}>{plan.name.split(" ")[1]}</Text>
                                            </StyledContainer>

                                        ) : (
                                            <StyledContainer sx={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100px'}}>
                                                {checked ?
                                                    <React.Fragment>
                                                        <Text sx={{fontWeight: '800', fontSize: '1.5rem', textTransform: 'uppercase', lineHeight: 1, textDecoration: 'line-through', color: '#cdc384'}}>${fullYearPrice(plan.productName)}</Text>
                                                        <Text sx={{fontWeight: '700', fontSize: '3rem', textTransform: 'uppercase', lineHeight: 1}}>{plan.name.split(" ")[0]}</Text>
                                                    </React.Fragment>
                                                :
                                                    <Text sx={{fontWeight: '700', fontSize: '3rem', textTransform: 'uppercase', lineHeight: 1}}>{plan.name.split(" ")[0]}</Text>
                                                }
                                                <Text sx={{fontWeight: '900', fontSize: '1.125rem', textTransform: 'uppercase'}}>{plan.name.split(" ")[1]}</Text>
                                            </StyledContainer>
                                        )}
                                        <StyledContainer sx={{marginTop: '40px'}}>
                                            <List sx={{padding: '5px 0', marginLeft: '5px'}}>
                                                {plan.features.marketing.map((feature, index) => (
                                                    <ListItem key={`feature-${index}`} sx={{padding: 0, alignItems: 'baseline'}}>
                                                        <Icon sx={{fontSize: '14px', marginRight: '10px', color: '#AB9F60'}} baseClassName='fa-regular' className={featureIcon(plan, index)} />
                                                        <Text>{feature}</Text>
                                                    </ListItem>
                                                ))}
                                                {plan.productName === 'NewsData' &&
                                                    <React.Fragment>
                                                        <Divider plan />
                                                        <StyledContainer sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <Text sx={{fontSize: '14px', textTransform: 'uppercase', textAlign: 'center'}}>Access to a Wealth of Global Mining Data with</Text>
                                                            <a href='https://marcopolo.tnm.global' target='_blank' rel='noopener noreferrer'>
                                                            <img style={{maxWidth: '215px'}} alt='Marco Polo logo' src='/images/marco-polo-logo.png' />
                                                            </a>
                                                        </StyledContainer>
                                                    </React.Fragment>
                                                }
                                            </List>
                                        </StyledContainer>
                                    </CardContent>
                                </StyledContainer>
                                <CardActions sx={{flexDirection: 'column', padding: '20px', paddingTop: 0}}>
                                    {plan.subscriptionTag === 'TNM_BASIC' ? (
                                        <Button type='outlined' onClick={() => handleRedirectBack()}>Current Membership</Button>
                                    ) : (
                                        <div style={{width: '100%'}}>
                                            {/* {trialEligible && (
                                                <Button type='text' onClick={() => selectTrial(plan.subscriptionTag)}>Get started with a free 14-day trial</Button>
                                            )} */}
                                            <Button sx={{marginLeft: '0 !important'}} onClick={() => selectMembership(plan.subscriptionTag)}>{planInfo.productName == plan.productName ? 'RENEW': 'UPGRADE'}</Button>
                                        </div>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        }
    </StyledWrapper>
  )
}